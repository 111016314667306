import React from 'react'
import Spinner from '../../Spinner'

const EscalateTicket = ({foundUserModal, resetModal, escalationTicketErrorMessage, isLoadingforEscalation, editSuccessMessage, escalateErrorMessage, ticketInfo, escalationReason, setEscalationReason, escalationRole, setEscalationRole, escalationComment, setEscalationComment, setEscalateErrorMessage, setEditSuccessMessage, escalateTicket}) => {
  return (
    <div>
         {foundUserModal && (
                        <div id="authentication-modal" tabIndex="-1" className="backdrop-blur-sm overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex" aria-modal="true" role="dialog">
                            <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                                <div className="relative bg-white rounded-lg shadow border-2 border-sky-800">
                                    <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center data-modal-toggle=authentication-modal">
                                    <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" onClick={()=>resetModal()}>
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                        
                                    <div className="py-6 px-6 lg:px-8">
                                        <h3 className="flex justify-center mb-4 text-xl font-medium text-gray-900">Escalate Ticket</h3>

                                        <div className='flex justify-center pt-5'>
                                            {escalationTicketErrorMessage && ( <h5 className="flex justify-center mb-4 text-xs font-medium text-red-700">* {escalationTicketErrorMessage}</h5>)} 
                                        </div>

                                            {isLoadingforEscalation && (
                                                <div className="flex justify-center mb-4">
                                                    <Spinner/>
                                                </div>
                                            )}
                                        {editSuccessMessage && (
                                            <h5 className="flex justify-center mb-4 text-xs font-medium text-green-700">* {editSuccessMessage}</h5>
                                        )}
                                        {escalateErrorMessage && (
                                            <h5 className="flex justify-center mb-4 text-xs font-medium text-red-700">* {escalateErrorMessage}</h5>
                                        )}

                                        <form className="space-y-6" action="#">

                                            <div className="relative">
                                                 <textarea id="message" rows="5" disabled className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-gray-500 focus:border-gray-500"
                                                 value={ticketInfo.ticketNumber+"\n"+ticketInfo.title+"\n"+ticketInfo.reporter+"\n"+"Status :"+ticketInfo.status+"\n"+"Escalated : "+ticketInfo.Escalation_status} placeholder="Your message..."></textarea>
                                            </div>

                                            <div className="relative">
                                                <select id="underline_select" className="block py-2.5 px-0 w-full text-sm text-gray-700 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer" defaultValue={escalationReason} onChange={(e) => setEscalationReason(e.target.value)}>
                                                    <option selected>Escalation Reason ↓</option> 
                                                    <option value="Key Account">Key Account</option>
                                                    <option value="Up Sell">Up Sell</option>
                                                    <option value="Renewal in 30 Days">Renewal in 30 Days</option>
                                                </select>
                                            </div>
                            
                                            <div className="relative">
                                                <select id="underline_select" className="block py-2.5 px-0 w-full text-sm text-gray-700 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer" defaultValue={escalationRole} onChange={(e) => setEscalationRole(e.target.value)}>
                                                    <option selected>Escalator Role ↓</option>
                                                    <option value="Exec">Exec</option>
                                                    <option value="KAM">KAM</option>
                                                    <option value="MD">MD</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>

                                            <div className="relative">
                                                <input type="text" name="floating_first_name" id="floating_first_name" className="block py-2.5 px-0 w-full text-sm text-gray-700 bg-transparent border-0 border-b-2 border-gray-700 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer" placeholder="Comment" defaultValue={escalationComment} onChange={(e) => setEscalationComment(e.target.value.trim())}/>
                                            </div>

                                            <div className='flex justify-center'>
                                                <button type="button" id="escalateButton" className="w-40 text-white bg-mgray hover:bg-myellowdark hover:text-black focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" 
                                                    onClick={() => 
                                                    {
                                                        setEscalateErrorMessage(false)
                                                        setEditSuccessMessage(false)
                                                        escalateTicket();
                                            
                                                        }}>Escalate Ticket
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
        )}
    </div>
  )
}

export default EscalateTicket
