import Layout from './components/authController/Layout'
import Login from './components/authController/Login';
import MainPage from './components/ticketController/MainPage'
import AllUser from './components/userController/AllUser'
import RequireAuth from './components/authController/RequireAuth';
import Unauthorized from './components/authController/Unauthorized';
import PersistLogin from './components/authController/PersistLogin';
import Navbar from './components/navigationController/Navbar';
import Logout from './components/authController/Logout';
import DisplayRole from './components/rolesController/DisplayRole'
import Missing from './Missing';
import {Routes, Route, Navigate} from 'react-router-dom'
import LoginTemplate from './LoginTemplate';
import Report from './components/reportController/Report';

function App() {
  return (
    <div>
    <Navbar/>
    {/* <Router basename="/escalation"> */}
    <Routes>
      <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route element={<PersistLogin />}>
            <Route path="login" element={<Login />} />
          </Route>
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="loggedOut" element={<Logout />} />
          <Route path="navbar" element={<Navbar />} />
          <Route path="logintemplate" element={<LoginTemplate />} />
          
          {/* we want to protect these routes */}
          <Route element={<PersistLogin />}>
            <Route path="/" element={<Navigate to="/ticket" replace />} />
              <Route path="ticket" element={<MainPage/>} />
              <Route element={<RequireAuth/>}>
                <Route path="allusers" element={<AllUser />} />
              </Route>

              <Route element={<RequireAuth/>}>
                <Route path="roles" element={<DisplayRole />} />
              </Route>

              <Route element={<RequireAuth/>}>
                <Route path="report" element={<Report />} />
              </Route>
          </Route>
            {/* catch all */}
            <Route path="*" element={<Missing />} />
      </Route>
  </Routes>
  {/* </Router> */}
  </div>
  );
}

export default App;
