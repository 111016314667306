import React from 'react'

const TicketInformation = ({ticketInfo, reasonForEscalatingTickett}) => {
  return (
    <div className="overflow-x-auto relative w-90">
    <table className="w-full text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50"></thead>
        <tbody>
            <tr className="bg-white border-y">
                <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                Ticket
                </th>
                <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                : &nbsp;&nbsp;{ticketInfo.ticketNumber}
                </th>
            </tr>
            <tr className="bg-white border-y">
                <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                Title
                </th>
                <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                : &nbsp;&nbsp;{ticketInfo.title}
                </th>
            </tr>
            <tr className="bg-white border-y">
                <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                Reporter
                </th>
                <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                : &nbsp;&nbsp;{ticketInfo.reporter}
                </th>
            </tr>
            <tr className="bg-white border-y">
                <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                Status
                </th>
                <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                : &nbsp;&nbsp;{ticketInfo.status}
                </th>
            </tr>
            <tr className="bg-white border-y">
                <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                Escalated
                </th>
                <th scope="row" className="py-4 px-6 font-xl text-gray-500 whitespace-nowrap">
                : &nbsp;&nbsp;{ticketInfo.Escalation_status}
                </th>
            </tr>
        </tbody>
    </table>
        {ticketInfo.Escalation_status === 'No' && (
            <div className='flex justify-center pt-4'>
                <button type="button" className="w-40 text-white bg-mgray hover:bg-myellowdark hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" 
                onClick={() => reasonForEscalatingTickett()}>Escalate</button>
            </div>
        )}
</div>
  )
}

export default TicketInformation
