import React from 'react'
import ReportDownload from './ReportDownload';
import ReportFilter from './ReportFilter';
import Spinner from "../../Spinner";
import Pagination from './Pagination';

const ReportTable = ({downloadFile1, showPaginationButtons, project, pageNo, handleFilter, increment, decrement, ticketDetails, setProject, filterError, years, setYear, setQuarter, download, downloadFile, downloadError}) => {
  return (
    <>
    <div className='max-w-6xl flex px-10 justify-center container mx-auto pt-20'>
    <div className="table-wrp block max-h-[38.5rem] overflow-x-auto relative shadow-md sm:rounded-lg bg-gray-500 w-10/12">
         <table className="w-full text-sm text-center text-gray-500">
                <caption className="sticky top-0 p-5 text-lg font-semibold text-left text-gray-700 bg-white border-b-2 border-gray-500">
                    <div className="flex items-center justify-center aligncenter justify-between">
                        <ReportFilter project={project} handleFilter={handleFilter} setProject={setProject} setYear={setYear} years={years} setQuarter={setQuarter}/>
                        {download && (
                            <ReportDownload downloadFile1={downloadFile1} downloadFile={downloadFile} downloadError={downloadError}/>
                        )}
                        
                    </div>
                    <div>
                    {filterError &&(
                            <h6 className="pt-2 text-xs font-sm text-red-700">* {filterError}</h6>
                    )}
                    </div>
                </caption> 

                <thead className="sticky top-[88px] text-xs text-gray-900 uppercase bg-white border-b-2 border-gray-500">
                    <tr>
                    <th scope="col" className="py-3 px-6">
                            Index
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Ticket
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Status
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Escalated By
                        </th>
                        <th scope="col" className="py-3 px-6">
                            Created at
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {!ticketDetails.tickets
                    ? <tr key="loading">
                        <td colSpan="5" className="bg-gray-200 p-4">
                            <div className="flex justify-center">
                                <Spinner/>
                            </div>
                    </td>
                      </tr>
                    : !ticketDetails.tickets.length
                    ? <tr key="nodata">
                          <td className="bg-gray-200 p-4" colSpan="5">No data available</td>
                      </tr>
                    : <> 
                            {ticketDetails.tickets.map((ticket, i) => (
                                <tr className="bg-white text-gray-900 border-b hover:bg-myellow">
                                        <th scope="row" className="py-4 px-6">
                                            {pageNo > 1 && pageNo-1}{i+1}
                                        </th>
                                        <td className="py-4 px-6 bg-myellow font-medium font-semibold text-gray-900">
                                            {ticket.ticket}
                                        </td>
                                        <td className="py-4 px-6">
                                            {ticket.isOpened? "Closed" : "Open"}
                                        </td>
                                        <td className="py-4 px-6 font-medium font-semibold text-gray-900">
                                            {ticket.email}
                                        </td>
                                        <td className="py-4 px-6">
                                            {new Date(ticket.createdAt).toLocaleString()}
                                        </td>
                                </tr>
                            ))}
                       </>
                  }
                </tbody>
        </table>
        </div>
    </div>
    <Pagination showPaginationButtons={showPaginationButtons} pageNo={pageNo} length={ticketDetails.tLength} increment={increment} decrement={decrement}/>
    </>
  )
}

export default ReportTable
