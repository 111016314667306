import { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Spinner from "../../Spinner";
import { limitValidation, roleValidation } from "../../validation/Regex";
import { errorValidation } from "../../validation/Error";
import AddNewRole from "./AddNewRole";
import EditRole from "./EditRole";
import RoleTable from "./RoleTable";
import useLogout from "../../hooks/useLogout";

const DisplayRole = () => {
    //Loading all the role
    const [roles, setRoles] = useState('');
    const logout = useLogout();
    // Updating the existing role 
    const [editRoleName, setEditRolelName] = useState('');
    const [editMonthlyLimit, setEditMonthlyLimit] = useState('');
    const [editInflightLimit, setEditInflightLimit] = useState('');
    const [editRolelId, setEditRolelId] = useState(''); 
    let [editRoleModal, setEditRoleModal] = useState(false);
    const [editErrorMessage, setEditErrorMessage] = useState(false)
    const [editSuccessMessage, setEditSuccessMessage] = useState(false)

    //Create new role
    let [addNewRoleModal, setAddNewRoleModal] = useState(false);
    const [addNewRoleModalErrorMessage, setAddNewRoleModalErrorMessage] = useState(false)
    const [addNewRoleModalSuccessMessage, setAddNewRoleModalSuccessMessage] = useState(false)
    const [isLoadingForAddRole, setIsLoadingForAddRole] = useState(false)
    const [roleName, setRoleName] = useState('')
    const [monthlyLimit, setMonthlyLimit] = useState('')
    const [inflightLimit, setInflightLimit] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [searchRole, setSearchRole] = useState("")


    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getRoles = async () => {
            try {
                const response = await axiosPrivate.get('/roles', {
                    signal: controller.signal
                });
                isMounted && setRoles(response.data);
            } catch (err) {
                await logout();
            }
        }

        getRoles();

        return () => {
            isMounted = false;
            controller.abort();
        }
        // eslint-disable-next-line
    }, [])


    const deleteRole = async (id) => {
        const isConfirmed = window.confirm('Are you sure to delete this role?')
        if(!isConfirmed) return;
        try {
            const response = await axiosPrivate.delete(`/roles/${id}`, {
                id:id
            });
            if(response?.data?.msg) return alert(response?.data?.msg)

            setRoles(roles.filter((val) => {
                    return val._id !==id;
            }))

            if(response?.data?.success) return alert(response?.data?.success)
        } catch (err) {
            await logout();
        }
    }

    const setRoleProps = async (id) => {
        editRoleModal = roles.find(element => element._id === id);
        if(!editRoleModal) return;
        setEditRolelId(editRoleModal._id)
        setEditRolelName(editRoleModal.roleName)
        setEditMonthlyLimit(editRoleModal.monthly)
        setEditInflightLimit(editRoleModal.inflight)
        setEditRoleModal(true)
    }

    const passErrorMessage = (msg) => {
        setEditErrorMessage(msg)
        setIsLoading(false)
    }

    const passErrorMessageForAddRole = (msg) => {
        setAddNewRoleModalErrorMessage(msg)
        setIsLoadingForAddRole(false)
    }

    const editRole = async () => {
        setIsLoading(true)
        if (limitValidation(editMonthlyLimit, editInflightLimit)) return passErrorMessage("Only numbers and Inflight value <= Monthly value")
        try {
            const response = await axiosPrivate.put('/roles', {
                id:editRolelId,
                monthly: editMonthlyLimit,
                inflight: editInflightLimit
            });

            if(response?.data?.msg) return setEditErrorMessage(response?.data?.msg)
            for (let i = 0; i < roles.length; i++) {
                if (editRolelId.match(roles[i]._id)) {
                    roles[i].roleName = editRoleName;
                    roles[i].monthly = editMonthlyLimit
                    roles[i].inflight = editInflightLimit;
                  break;
                }
              }
              setIsLoading(false)
              if(response?.data?.success) return setEditSuccessMessage(response.data.success);
            
        } catch (err) {
            await logout();
        }
    }

    const addRole = async () => {
        setIsLoadingForAddRole(true)
        if (roleValidation(roleName)) return passErrorMessageForAddRole(" Length : 10<60 and Alphanumeric, -, _ are aloowed")
        if (limitValidation(monthlyLimit, inflightLimit)) return passErrorMessageForAddRole("Only numbers and Inflight value <= Monthly value")
          try {
              const response = await axiosPrivate.post('/roles', {
                  roleName:roleName,
                  monthlyLimit: monthlyLimit,
                  inflightLimit: inflightLimit
              });
              setIsLoadingForAddRole(false)
              if(response?.data?.msg) return setAddNewRoleModalErrorMessage(response?.data?.msg)
              if(response?.data?.success) {
                setRoles([...roles, response?.data?.newRole])
                return setAddNewRoleModalSuccessMessage(response?.data?.success)
              }
          } catch (err) {
            await logout();
          }
      }

 

    return (
        <>
            <RoleTable setSearchRole={setSearchRole} setAddNewRoleModal={setAddNewRoleModal} roles={roles} searchRole={searchRole} setRoleProps={setRoleProps} deleteRole={deleteRole}  />        
            {editRoleModal && (
                <EditRole setEditRoleModal={setEditRoleModal} setIsLoading={setIsLoading} isLoading={isLoading} Spinner={Spinner} editSuccessMessage={editSuccessMessage} editErrorMessage={editErrorMessage} editRoleName={editRoleName} setEditRolelName={setEditRolelName} editMonthlyLimit={editMonthlyLimit} setEditMonthlyLimit={setEditMonthlyLimit} editInflightLimit={editInflightLimit} setEditInflightLimit={setEditInflightLimit} setEditErrorMessage={setEditErrorMessage} setEditSuccessMessage={setEditSuccessMessage} editRole={editRole}/>
            )}
            {addNewRoleModal && (
                <AddNewRole addNewRoleModal={addNewRoleModal} setAddNewRoleModal={setAddNewRoleModal} isLoadingForAddRole={isLoadingForAddRole} addNewRoleModalSuccessMessage={addNewRoleModalSuccessMessage} addNewRoleModalErrorMessage={addNewRoleModalErrorMessage} setRoleName={setRoleName} setMonthlyLimit={setMonthlyLimit} setInflightLimit={setInflightLimit} setAddNewRoleModalErrorMessage={setAddNewRoleModalErrorMessage} setAddNewRoleModalSuccessMessage={setAddNewRoleModalSuccessMessage} addRole={addRole}/>
            )}
        </>
    );
};

export default DisplayRole;