import { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { errorValidation } from "../../validation/Error";
import { roleValidation, limitValidation, emailValidation } from "../../validation/Regex";
import AddNewUser from "./AddNewUser";
import EditUser from "./EditUser";
import UserTable from "./UserTable";
import useLogout from "../../hooks/useLogout";

const Users = () => {
    const logout = useLogout();
    const { auth, setAuth } = useAuth(); 
    const [users, setUsers] = useState('');
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false)
    const [searchUser, setSearchUser] = useState("")

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        const getUsers = async () => {
            setIsLoading(true)
            try {
                const response = await axiosPrivate.get('/users', {
                    signal: controller.signal
                });
                setIsLoading(false)
                isMounted && setUsers(response.data);
            } catch (err) {
                await logout();
            }
        }

        getUsers();

        return () => {
            isMounted = false;
            controller.abort();
        }
        //eslint-disable-next-line
    }, [])


    const deleteUser = async (id) => {
        const isConfirmed = window.confirm('Are you sure to delete this user?')
        if(!isConfirmed) return;        
        try {
            const response = await axiosPrivate.delete(`/users/${id}`, {
                id:id
            });
            if(response?.data?.msg) return alert(response?.data?.msg)
            setUsers(users.filter((val) => {
                    return val._id !==id;
            }))
            if(response?.data?.success) return alert(response?.data?.success)
        } catch (err) {
            await logout();
        }
    }



    // Updating the existing role 
    const [editUserName, setEditUserName] = useState('');
    const [editUserEmail, setEditUserEmail] = useState('');
    const [editTitle, setEditTitle] = useState('');
    const [editMonthlyLimit, setEditMonthlyLimit] = useState(0);
    const [editInflightLimit, setEditInflightLimit] = useState(0);
    const [editUserlId, setEditUserlId] = useState(''); 
    let [foundUserModal, setFoundUserModal] = useState(false);
    const [editErrorMessage, setEditErrorMessage] = useState(false)
    const [editSuccessMessage, setEditSuccessMessage] = useState(false)

    const setUserProps = async (id) => {
        foundUserModal = users.find(element => element._id === id);
        if(!foundUserModal) return;
        setEditUserlId(foundUserModal._id || '')
        setEditUserName(foundUserModal.username || '')
        setEditUserEmail(foundUserModal.email || '')
        setEditTitle(foundUserModal.title || '')
        setEditMonthlyLimit(foundUserModal.userLimits.monthly || '')
        setEditInflightLimit(foundUserModal.userLimits.inflight || '')
        setFoundUserModal(true)
    }

    const passErrorMessage = (msg) => {
        setEditErrorMessage(msg)
        setIsLoading(false)
    }

    const editUser = async () => { 
        setIsLoading(true)
        if (roleValidation(editTitle)) return passErrorMessage(" Length : 10<60 and Alphanumeric, -, _ are allowed")
        if (limitValidation(editMonthlyLimit, editInflightLimit)) return passErrorMessage("Only numbers and Inflight value <= Monthly value")
        try {
            const response = await axiosPrivate.put('/users', {
                id:editUserlId,
                title: editTitle,
                monthly: editMonthlyLimit,
                inflight: editInflightLimit
            
            });
            setIsLoading(false)
            if(response?.data?.msg) return setEditErrorMessage(response?.data?.msg)
            for (let i = 0; i < users.length; i++) {
                if (editUserlId === users[i]._id) {
                    users[i].title = editTitle;
                    users[i].userLimits = {
                        monthly: editMonthlyLimit,
                        inflight: editInflightLimit
                    }
                  break;
                }
              }

              if(response?.data?.success) return setEditSuccessMessage(response.data.success);
        } catch (err) {
            await logout();
        }
    }

    const [checked, setChecked] = useState(false);
    const handleChange = () => {
       setChecked(!checked);
      };


      // Adding new User
const [roleName, setRoleName] = useState('')
const [email, setEmail] = useState('')
const [isChecked, setIsChecked] = useState(false)
let [addNewUserModal, setAddNewUserModal] = useState(false);
const [addNewUserModalErrorMessage, setAddNewUserModalErrorMessage] = useState(false)
const [addNewUserModalSuccessMessage, setAddNewUserModalSuccessMessage] = useState(false)
const [isLoadingForAddUser, setIsLoadingForAddUser] = useState(false)

const passErrorMessageForAddUser = (msg) => {
    setAddNewUserModalErrorMessage(msg)
    setIsLoadingForAddUser(false)
}

const addNewUser = async () => {
    setIsLoadingForAddUser(true)
    if (roleValidation(roleName)) return passErrorMessageForAddUser(" Length : 10<60 and Alphanumeric, -, _ are allowed") 
    if (emailValidation(email)) return passErrorMessageForAddUser(" Enter valid email and allowed domain : @meltwater.com")
    if(!roleName || !email) return passErrorMessageForAddUser("Enter all the details");
      try {
          const response = await axiosPrivate.post('/users', {
              title:roleName,
              email: email,
              isAdmin: isChecked
          });
          setIsLoadingForAddUser(false)
          if(response?.data?.msg) return passErrorMessageForAddUser(response?.data?.msg)
          if(response?.data?.success) {
            setUsers([...users, response?.data?.newUser])
            return setAddNewUserModalSuccessMessage(response?.data?.success)
          }
      } catch (err) {
        await logout();
      }
  }

    return (
        <>
            <UserTable setSearchUser={setSearchUser} setAddNewUserModal={setAddNewUserModal} handleChange={handleChange} users={users} checked={checked} searchUser={searchUser} auth={auth} setUserProps={setUserProps} deleteUser={deleteUser}/>
            <EditUser foundUserModal={foundUserModal} setFoundUserModal={setFoundUserModal} isLoading={isLoading} editSuccessMessage={editSuccessMessage} editErrorMessage={editErrorMessage} editUserName={editUserName} setEditUserName={setEditUserName} editUserEmail={editUserEmail} setEditUserEmail={setEditUserEmail} editTitle={editTitle} setEditTitle={setEditTitle} editMonthlyLimit={editMonthlyLimit} setEditMonthlyLimit={setEditMonthlyLimit} editInflightLimit={editInflightLimit} setEditInflightLimit={setEditInflightLimit} setEditErrorMessage={setEditErrorMessage} setEditSuccessMessage={setEditSuccessMessage} editUser={editUser}/>
            <AddNewUser addNewUserModal={addNewUserModal} setAddNewUserModal={setAddNewUserModal} isLoadingForAddUser={isLoadingForAddUser} addNewUserModalSuccessMessage={addNewUserModalSuccessMessage} addNewUserModalErrorMessage={addNewUserModalErrorMessage} setEmail={setEmail} setRoleName={setRoleName} setIsChecked={setIsChecked} isChecked={isChecked} setAddNewUserModalErrorMessage={setAddNewUserModalErrorMessage} setAddNewUserModalSuccessMessage={setAddNewUserModalSuccessMessage} addNewUser ={addNewUser} />
        </>
    );
};

export default Users;