import React from 'react'
import SearchUser from './SearchUser';
import OnlyAdminCheckBox from './OnlyAdminCheckBox';
import AddUserIcon from './AddUserIcon';
import Spinner from '../../Spinner';

const UserTable = ({setSearchUser, setAddNewUserModal, handleChange, users, checked, searchUser, auth, setUserProps, deleteUser}) => {
  return (
    <div className='max-w-6xl flex px-10 justify-center container mx-auto pt-20'>
    <div className="table-wrp block max-h-[38.5rem] overflow-x-auto relative shadow-md sm:rounded-lg bg-gray-500 w-10/12">
    <table className="w-full text-sm text-center text-gray-500">
                        <caption className="sticky top-0 p-5 text-lg font-semibold text-left text-gray-700 bg-white border-b-2 border-gray-500">
                            <div className="flex items-center justify-between">
                                <SearchUser setSearchUser={setSearchUser} />

                                <div className="flex bg-white text-center pt-3 pr-2">
                                    <AddUserIcon setAddNewUserModal={setAddNewUserModal}/>
                                    <OnlyAdminCheckBox checked={checked} handleChange={handleChange}/>

                                </div>
                            </div>
                        </caption>

                        <thead className="sticky top-[94px] text-xs text-gray-900 uppercase bg-white border-b-2 border-gray-500">
                            <tr>
                            <th scope="col" className="py-3 px-6">
                                    Index
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Name
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Position
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Action
                                </th>
                            </tr>
                        </thead>

                        <>
                        <tbody>
                        {!users
                            ? <tr>
                                <td colSpan="4" className="bg-gray-200 p-4">
                                    <div className="flex justify-center">
                                        <Spinner/>
                                    </div>
                            </td>
                            </tr>
                            : !users.length
                            ? <tr>
                                <td className="bg-gray-200 p-4" colSpan="5">No data available</td>
                            </tr>
                            : <> 
                            {users.filter((val) => {
                                if(!checked) {
                                    if(!searchUser){
                                        return val;
                                    }else if(val.username.toLowerCase().includes(searchUser.toLowerCase())) {
                                        return val;
                                    }
                                }else{
                                    return val.isAdmin === true;
                                }
                            }).map((user, i) => {
                            return (
                                <tr key={i} className="bg-white text-gray-900 border-b hover:bg-myellow">
                                        <th className="p-4"> {i+1} </th>
                                        <th scope="row" className="flex items-center py-4 px-6 whitespace-nowrap">

                                               <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 rounded-full text-mgray" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                                                </svg>     

                                                <div className="pl-3">
                                                    <div className="flex">
                                                        <div className="font-semibold text-left text-gray-900">
                                                            {user?.username}
                                                        </div>
                                                         {user.isAdmin && (
                                                            <>
                                                            <span>&nbsp;</span>
                                                            <div>       
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                                                                </svg>
                                                            </div>
                                                            </>
                                                          )}
                                                    </div>
                                                    <div className="font-normal text-gray-900">
                                                        {user?.email}
                                                    </div>
                                                </div>  
                                        </th>
                                        <td className="py-4 px-6">
                                            {user?.title} 
                                        </td>
                        
                                        <td className="py-4 px-6 align-middle">
                                            <div className='flex space-x-2 justify-center'>
                                                {user.email !== auth.email && (<>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-700 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={() => {setUserProps(user?._id)}}>
                                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-orange-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} onClick={() => {deleteUser(user?._id)}}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                </svg>
                                                </>
                                                )}
                                            </div>
                                        </td>
                                </tr>
                            )}
                            )}
                            </> }
                        </tbody>
                    </>
                </table>
                </div>
        </div>
  )
}

export default UserTable