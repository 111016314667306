import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuth = () => {
    const {auth} = useAuth();
    const location = useLocation();

    return (
        auth?.isAdmin
        ? <Outlet/>
        : auth?.email
        ?  <Navigate to="/404" state={{from: location}} replace />
        // ?  <Navigate to="/unauthorized" state={{from: location}} replace />
        :  <Navigate to="/login" state={{from: location}} replace />
       
    )
}

export default RequireAuth;