import React from 'react'

const OnlyAdminCheckBox = ({checked, handleChange}) => {
  return (
    <div className="flex items-center mb-4">
        <input type="checkbox"
            className="w-4 h-4 text-gray-600 bg-gray-100 rounded border-gray-300 focus:ring-gray-500 focus:ring-2"
            label="Value 1"
            value={checked}
            onChange={handleChange}
        />
    <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium text-gray-900">Only Admins</label>
</div>
  )
}

export default OnlyAdminCheckBox