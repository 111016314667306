import React from 'react'
import { Link } from 'react-router-dom'

const MainMenu = ({auth}) => {
  return (
    <div className='flex items-center'>
    <img alt="logo" className='h-20 w-40 mr-4' src="assets/bitmap.svg"/>
    <ul className='hidden md:flex'>
        {auth.username && (
            <Link className="ml-5" to="/ticket"><span>Ticket</span>
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
            </svg> */}
          </Link>

        )}
        {auth.isAdmin && (
            <>
              <Link className="ml-5" to="/allusers"><span>Users</span></Link>
              <Link className="ml-5" to="/roles"><span>Roles</span></Link>
              <Link className="ml-5" to="/report"><span>Reports</span></Link>
            </>

        )}        
    </ul>
    </div>
  )
}

export default MainMenu
