import React from 'react'
import { Link } from 'react-router-dom'

const HamburgerMenu = ({nav, auth}) => {
  return (
    <ul className={!nav ? 'hidden' : 'absolute bg-myellowdark w-full px-8 text-center lg:hidden'}>
            {auth.username && (
                <li className='border-b-2 border-gray-500 w-full'><Link to="/ticket">Ticket</Link></li>
            )}
            {auth.isAdmin && (
                <>
                <li className='border-b-2 border-gray-500 w-full'><Link to="/allusers">User</Link></li>
                <li className='border-b-2 border-gray-500 w-full'><Link to="/roles">Role</Link></li>
                </>
            )}  
    </ul>
  )
}

export default HamburgerMenu
