import React from 'react'

const AddUserIcon = ({setAddNewUserModal}) => {
  return (
    <div className="flex items-center mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hover:text-myellowdark cursor-pointer mr-5 rounded-full" viewBox="0 0 20 20" fill="currentColor" onClick={() => {setAddNewUserModal(true)}}>
            <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
        </svg>
    </div>
  )
}

export default AddUserIcon