import React from 'react'
import Spinner from '../../Spinner'

const UpdateLimits = ({setIsChecked, isChecked,auth,updateDesignation,isLoadingforDesignationUpdate,updateDesignationErrorMessage,updateDesignationSuccessMessage}) => {
  return (
    <div>
        <div className="flex justify-center items-center mb-4 pt-4">
                <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-mgray bg-gray-100 rounded border-gray-300 focus:ring-gray-500 focus:ring-2" onChange={() => {
                    setIsChecked(!isChecked)
                }} />
                <label htmlFor="default-checkbox" className="ml-2 text-sm font-medium dark:text-gray-900">Update limits?</label>
        </div>
        {isChecked && (
        <>
            <div className="flex justify-center items-center mb-4">
                <label className="ml-2 text-sm font-medium dark:text-gray-900">{auth.title}</label>
            </div>
            <div className='flex justify-center pb-2'>
                <button type="button" id="updateDesignationButton" className="w-20 text-white bg-mgray hover:bg-myellowdark hover:text-black font-medium rounded-lg text-sm py-0.5 text-center" 
                onClick={updateDesignation}>Update</button>
            </div>
                <div className='flex justify-center pt-5'>
                {isLoadingforDesignationUpdate && (
                    <Spinner />
            )}
                {updateDesignationErrorMessage && ( <h6 className="flex justify-center mb-4 text-xs font-sm text-red-700">* {updateDesignationErrorMessage}</h6>)} 
                {updateDesignationSuccessMessage && ( <h6 className="flex justify-center mb-4 text-xs font-sm text-green-700">* {updateDesignationSuccessMessage}</h6>)} 
            </div>
            </>
        )}
    </div>
  )
}

export default UpdateLimits
