export const limitValidation = function(editMonthlyLimit, editInflightLimit) {
    return (!/^[0-9]{0,2}$/.test(parseInt(editMonthlyLimit)) || !/^[0-9]{0,2}$/.test(parseInt(editInflightLimit)) || parseInt(editInflightLimit) > parseInt(editMonthlyLimit))
}

export const roleValidation = function(editTitle) {
    return (!/^[a-zA-Z0-9-_ ]{10,60}$/.test(editTitle))
}

export const escalateTicketValidation = function(escalationReason,escalationRole,escalationComment) {
    return escalationReason==="Escalation Reason ↓" || escalationRole==="Escalator Role ↓" || !/^[a-zA-Z0-9-_: ]{4,60}$/.test(escalationComment)
}

export const ticketValidation = function(inputTicket) {
    return !/^(MWTR|CJRNL|CEBR)[-]{1}[0-9]{2,11}$/.test(inputTicket)
}

export const emailValidation = function(email) {
    return !/^[A-Za-z0-9._%+-]+@meltwater\.com$/i.test(email)
}