import React from 'react'

const EditRole = ({ setEditRoleModal, setIsLoading, isLoading, Spinner, editSuccessMessage, editErrorMessage, editRoleName, setEditRolelName, editMonthlyLimit, setEditMonthlyLimit, editInflightLimit, setEditInflightLimit, setEditErrorMessage, setEditSuccessMessage, editRole,}) => {
  return (
    <div id="authentication-modal" tabIndex="-1" className="backdrop-blur-sm overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex" aria-modal="true" role="dialog">
    <div className="relative p-4 w-full max-w-md h-full md:h-auto">
        <div className="relative bg-white rounded-lg shadow border-2 border-sky-800">
            <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="authentication-modal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" onClick={()=>
                    {  setEditRoleModal(false) 
                        setEditErrorMessage(false)
                        setEditSuccessMessage(false)
                    setIsLoading(false)}
                    }>
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>

            <div className="py-6 px-6 lg:px-8">
                <h3 className="flex justify-center mb-4 text-xl font-medium text-gray-900">Update Title & Limit</h3>
                   {isLoading && (
                                <div className="flex justify-center mb-4">
                                    <Spinner/>
                                </div>
                            )}
                    {editSuccessMessage && (
                        <h5 className="flex justify-center mb-4 text-xs font-medium text-green-700">* {editSuccessMessage}</h5>
                    )}
                    {editErrorMessage && (
                        <h5 className="flex justify-center mb-4 text-xs font-medium text-red-700">* {editErrorMessage}</h5>
                    )}

                <form className="space-y-6" action="#">
                    <div className="relative">
                        <input disabled type="text" id="floating_filled" className="block bg-slate-200 rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 border-0 border-b-2 border-gray-300 dark:border-gray-600 dark:focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer" defaultValue={editRoleName} onChange={(e) => {setEditRolelName(e.target.value)}}/>
                        <label htmlFor="floating_filled" className="absolute text-sm text-gray-900 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-gray-900 peer-focus:dark:text-blgrayue-900 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">Role Name</label>
                    </div>
    
                    <div className="relative">
                        <input type="number" id="floating_filled" className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900   border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer" defaultValue={editMonthlyLimit} onChange={(e) => {setEditMonthlyLimit(e.target.value)}}/>
                        <label htmlFor="floating_filled" className="absolute text-sm text-gray-900 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-gray-900 peer-focus:dark:text-blgrayue-900 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">Monthly Limit</label>
                    </div>

                    <div className="relative">
                        <input type="number" id="floating_filled" className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900   border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer" defaultValue={editInflightLimit} onChange={(e) => {setEditInflightLimit(e.target.value)}}/>
                        <label htmlFor="floating_filled" className="absolute text-sm text-gray-900 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-gray-900 peer-focus:dark:text-blgrayue-900 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4">Inflight Limit</label>
                    </div>

                    <div className='flex justify-center'>
                        <button type="button" className="w-40 text-white bg-mgray hover:bg-myellowdark hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" 
                            onClick={() => 
                            {
                                setEditErrorMessage(false)
                                setEditSuccessMessage(false)
                                editRole();
                            }}>Update Role
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
  )
}

export default EditRole
