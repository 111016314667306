import React from 'react'

const AddNewRoleIcon = ({setAddNewRoleModal}) => {
  return (
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 hover:text-myellowdark cursor-pointer mr-5 rounded-full" viewBox="0 0 20 20" fill="currentColor" onClick={() => {setAddNewRoleModal(true)}} >
            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
        </svg>
    </div>
  )
}

export default AddNewRoleIcon
