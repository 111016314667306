import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import * as XLSX from 'xlsx';
import ReportTable from "./ReportTable";
import useLogout from "../../hooks/useLogout";

const Report = () => {
    const logout = useLogout();
    const [ticketDetails, setTicketDetails] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const [download, setDownload] = useState(false);
    const [filterError, setFilterError] = useState(false)
    const [project, setProject] = useState("last_ten_escalations");
    const [quarter, setQuarter] = useState("quarter");
    const [year, setYear] = useState("year");
    const [showPaginationButtons, setShowPaginationButtons] = useState(false);
    const currentYear = new Date().getFullYear(); 
    const startYear = 2020;
    const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);
    

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getRecentEscalations = async () => {
            try {
                const response = await axiosPrivate.get('/report', {
                    signal: controller.signal
                });
                isMounted && setTicketDetails(response.data);
                if(!(!response.data)) setDownload(true)
            } catch (err) {
                await logout();
            }
        }

        getRecentEscalations();

        return () => {
            isMounted = false;
            controller.abort();
        }
        // eslint-disable-next-line
    }, [])

    const [downloadError, setDownloadError] = useState(false)
    const downloadFile = async () => {
        try{
          setDownloadError(false)
          const worksheet = XLSX.utils.json_to_sheet(ticketDetails.tickets);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, project || "Report");
          XLSX.writeFile(workbook, "Report.xlsx");
        }catch(e){
            console.log(e)
          setDownloadError(true)
        }
      }



    const pageNo = useRef(1);
    const increment = () => { 
        pageNo.current++
        handleFilter()
    }
    const decrement = () => {
        if(pageNo.current !== 1) {
            pageNo.current--
            handleFilter()
    }
}

    const handleFilter = async () => {
        console.log(project)
        try {
            setFilterError(false)
            if (project === 'last_ten_escalations') {
                return pageNo.current = 1;
            } else if (year === 'year'){
             return setFilterError("Please select the valid Year")
            } else if (quarter === 'quarter'){
             return setFilterError("Please select the valid Quarter")
            }
            getEscalationsTickets();
        } catch (err) {
            await logout();
        }
    }

    const getEscalationsTickets = async () => {
        try {
                let url = project === 'last_ten_escalations' ? '/report' : `/report?project=${project}&&year=${year}&&quarter=${quarter}&&pageNo=${pageNo.current}`
                const response = await axiosPrivate.get(url);
                if(response.data.msg) return setTicketDetails({});
                if(project !== 'last_ten_escalations') setShowPaginationButtons(true)
                setTicketDetails(response.data);
        } catch (err) {
            await logout();
        }
    }


  return (
    <ReportTable showPaginationButtons={showPaginationButtons} project={project} pageNo={pageNo.current} handleFilter={handleFilter} increment={increment} decrement={decrement} ticketDetails={ticketDetails} setProject={setProject} filterError={filterError} setYear={setYear} years={years} setQuarter={setQuarter} download={download} downloadFile={downloadFile} downloadError={downloadError}/>
  )
}

export default Report
