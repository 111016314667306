import React from 'react'

const ReportDownload = ({downloadFile, downloadFile1, downloadError}) => {
  return (

    <div className="flex items-center mt-3">
        <svg xmlns="http://www.w3.org/2000/svg" onClick={downloadFile} ondblclick={downloadFile1} viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7 hover:text-myellowdark cursor-pointer rounded-full">
            <path fillRule="evenodd" d="M19.5 21a3 3 0 003-3V9a3 3 0 00-3-3h-5.379a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H4.5a3 3 0 00-3 3v12a3 3 0 003 3h15zm-6.75-10.5a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V10.5z" clipRule="evenodd" />
        </svg>
        {downloadError &&(
                <h6 className="flex justify-center text-xs font-sm text-red-700 ml-2"> Error</h6>
        )}
    </div>
  )
}

export default ReportDownload
