import React, {useState} from 'react'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { googleLogout } from '@react-oauth/google';
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import useLogout from '../../hooks/useLogout'
import HamburgerMenu from './HamburgerMenu';
import MainMenu from './MainMenu';
import ProfileImage from './ProfileImage';
import HamburgetMenuIcon from './HamburgetMenuIcon';


const Navbar = () => {
  let userProfile = '';
  let decodeToken = '';
  try{
    userProfile = Cookies.get('gjwt')
    decodeToken = jwt_decode(userProfile)
  }catch(e){
    console.log("Error")
  }
  const navigate = useNavigate();
  const logout = useLogout();
  let {auth} = useAuth();
  const [nav, setNav] = useState(false)
  const handleClick = () => setNav(!nav)
  const signOut = async () => {
      Cookies.remove('name')
      await googleLogout();
      await logout();
      navigate('/login');
}
  
  return (
    <>
        <div>
          <div className='w-screen h-[70px] z-10 bg-myellowdark drop-shadow-lg'>
              <div className='px-10 flex justify-between items-center w-full h-full'>
                    <MainMenu auth={auth}/>
                    <ProfileImage decodeToken={decodeToken} nav={nav} signOut={signOut} auth={auth} />
                    <HamburgetMenuIcon nav={nav} handleClick={handleClick} auth={auth}/>
              </div>
             <HamburgerMenu nav={nav} auth={auth}/>
          </div> 
        </div>
    </>


  )
}

export default Navbar
