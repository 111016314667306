import { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { escalateTicketValidation,ticketValidation } from "../../validation/Regex";
import { errorValidation } from "../../validation/Error";
import useAuth from "../../hooks/useAuth";
import EscalateTicket from "./EscalateTicket";
import TicketInformation from "./TicketInformation";
import AssignedLimits from "./AssignedLimits";
import UpdateLimits from "./UpdateLimits";
import FetchTicket from "./FetchTicket";
import useLogout from "../../hooks/useLogout";

const MainPage = () => {
    const {auth} = useAuth();
    const logout = useLogout();
    const [inputTicket, setInputTicket] = useState();
    const [ticketInfo, setTicketInfo] = useState();
    const [limit, setLimit] = useState();
    const [TicketErrorMessage, setTicketErrorMessage] = useState(false)
    const [escalationTicketErrorMessage, setEscalationTicketErrorMessage] = useState(false)
    const [isLoading, setIsLoading] = useState(false) 
    const [isLoadingforEscalation, setIsLoadingforEscalation] = useState(false) 
    const axiosPrivate = useAxiosPrivate();
    const [isChecked, setIsChecked] = useState(false)
    const [isLoadingforDesignationUpdate, setIsLoadingforDesignationUpdate] = useState(false)
    const [updateDesignationErrorMessage, setUpdateDesignationErrorMessage] = useState(false)
    const [updateDesignationSuccessMessage, setUpdateDesignationSuccessMessage] = useState(false)
    const navigate = useNavigate();
    

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getRoles = async () => {
            try {
                const response = await axiosPrivate.get('/users/limits', {
                    signal: controller.signal
                });
                setIsLoading(false)
                if(response.data.msg) return alert("No data to")
                isMounted && setLimit(response.data);
            } catch (err) {
                await logout();
                navigate('/login')
            }
        }

        getRoles();

        return () => {
            isMounted = false;
            controller.abort();
        }
        // eslint-disable-next-line
    }, [])




    const fetchTicket = async () => {
        setIsLoading(false)
        setTicketErrorMessage(false)
        setTicketInfo(false)
        if(ticketValidation(inputTicket)) return setTicketErrorMessage("Enter valid ticket.")
        setIsLoading(true)
        try {
            const response = await axiosPrivate.post('/ticket', {
                "ticket": inputTicket.toUpperCase()
            });
            setIsLoading(false)
            if(response?.data?.msg) return setTicketErrorMessage(response?.data?.msg);
            setTicketInfo(response.data);
        } catch (err) {
            await logout();
        }
    }


    const updateDesignation = async () => {
        document.getElementById("updateDesignationButton").disabled = true;
        setIsLoadingforDesignationUpdate(false)
        setUpdateDesignationErrorMessage(false)
        setUpdateDesignationSuccessMessage(false)
        setIsLoadingforDesignationUpdate(true)
        try {
            const response = await axiosPrivate.put('/roles/updateDesignation');
            setIsLoadingforDesignationUpdate(false)
            if(response?.data?.msg) {
                document.getElementById("updateDesignationButton").disabled = false;
                return setUpdateDesignationErrorMessage(response?.data?.msg);
            } 
            if(response?.data?.success) setUpdateDesignationSuccessMessage(response?.data?.success);
            document.getElementById("updateDesignationButton").disabled = false;
            console.log(response.data)
        } catch (err) {
            await logout();
        }
    }

    const escalateTicket = async () => {
        document.getElementById("escalateButton").disabled = true;
        setIsLoadingforEscalation(false)
        setEscalationTicketErrorMessage(false)
        setIsLoadingforEscalation(true)
        if(escalateTicketValidation(escalationReason,escalationRole,escalationComment)) {
            setIsLoadingforEscalation(false)
            document.getElementById("escalateButton").disabled = false;
            return setEscalateErrorMessage("Select valid Reason & Role and Alphaneuimeric, -, _ are allowed")
        }
        try {
            const response = await axiosPrivate.post('/ticket/escalate', {
                "ticket": ticketInfo.ticketNumber,
                "escalationReason": escalationReason,
                "escalationRole": escalationRole,
                "escalationComment": escalationComment
            });

            setIsLoadingforEscalation(false)
            if(response?.data?.msg) return setEscalationTicketErrorMessage(response?.data?.msg);
            setEditSuccessMessage(response?.data?.success)
        } catch (err) {
            await logout();
        }
    }


    let [foundUserModal, setFoundUserModal] = useState(false);
    const [escalateErrorMessage, setEscalateErrorMessage] = useState(false)
    const [editSuccessMessage, setEditSuccessMessage] = useState(false)
    const [escalationReason, setEscalationReason] = useState("Escalator Reason ↓")
    const [escalationRole, setEscalationRole] = useState("Escalator Role ↓")
    const [escalationComment, setEscalationComment] = useState("MRR: ")
    const reasonForEscalatingTickett = () => {
        setFoundUserModal(true);
    }

    const resetModal = () => {
        document.getElementById("escalateButton").disabled = false;
         setFoundUserModal(false) 
         setEscalateErrorMessage(false)
         setEditSuccessMessage(false)
         setIsLoadingforEscalation(false)
         setEscalationReason("Escalator Reason ↓")
         setEscalationRole("Escalator Role ↓")
         setEscalationComment("MRR: ")
    }


    return (
        <>
            <div className='max-w-6xl flex px-10 justify-center container mx-auto pt-20 space-x-4'>
                <div className="overflow-x-auto relative shadow-md w-90">
                    <AssignedLimits limit={limit}/>
                    <UpdateLimits setIsChecked={setIsChecked} isChecked={isChecked} auth={auth} updateDesignation={updateDesignation} isLoadingforDesignationUpdate={isLoadingforDesignationUpdate} updateDesignationErrorMessage={updateDesignationErrorMessage} updateDesignationSuccessMessage={updateDesignationSuccessMessage}/>
                </div>
            </div>
            <FetchTicket setInputTicket={setInputTicket} fetchTicket={fetchTicket} TicketErrorMessage={TicketErrorMessage} isLoading={isLoading}/>
            {ticketInfo && (
                <div className='max-w-6xl flex px-10 justify-center container mx-auto pt-6'>  
                    <TicketInformation ticketInfo={ticketInfo} reasonForEscalatingTickett={reasonForEscalatingTickett} /> 
                    <EscalateTicket foundUserModal={foundUserModal} resetModal={resetModal} escalationTicketErrorMessage={escalationTicketErrorMessage} isLoadingforEscalation={isLoadingforEscalation} editSuccessMessage={editSuccessMessage} escalateErrorMessage={escalateErrorMessage} ticketInfo={ticketInfo} escalationReason={escalationReason} setEscalationReason={setEscalationReason} escalationRole={escalationRole} setEscalationRole={setEscalationRole} escalationComment={escalationComment} setEscalationComment={setEscalationComment} setEscalateErrorMessage={setEscalateErrorMessage} setEditSuccessMessage={setEditSuccessMessage} escalateTicket={escalateTicket}/>
                </div>
            )}

        </>
);
};

export default MainPage;