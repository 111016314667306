import React from 'react'
import {MenuIcon, XIcon } from '@heroicons/react/outline'

const HamburgetMenuIcon = ({auth, nav, handleClick}) => {
  return (
    <>
      {auth.username && (
            <div className='md:hidden' onClick={handleClick}>
                {!nav ? <MenuIcon className='w-5'/> :<XIcon className='w-5'/> }
            </div>
        )}
    </>
  )
}

export default HamburgetMenuIcon
