import React from 'react'
import Spinner from '../../Spinner'

const AddNewRole = ({addNewRoleModal, setAddNewRoleModal,setAddNewRoleModalErrorMessage,isLoadingForAddRole,addNewRoleModalSuccessMessage,addNewRoleModalErrorMessage,setRoleName,setMonthlyLimit,setInflightLimit,setAddNewRoleModalSuccessMessage,addRole}) => {
  return (
    <div>
            <div id="authentication-modal" tabIndex="-1" className="backdrop-blur-sm overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center flex" aria-modal="true" role="dialog">
            <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                <div className="relative bg-white rounded-lg shadow border-2 border-sky-800">
                    <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-toggle="authentication-modal">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" onClick={()=>
                            {  setAddNewRoleModal(false) 
                                setAddNewRoleModalErrorMessage(false)
                                setAddNewRoleModalSuccessMessage(false)}
                            }>
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
        
                    <div className="py-6 px-6 lg:px-8">
                        <h3 className="flex justify-center mb-4 text-xl font-medium text-gray-900">Add New Role</h3>
                            {isLoadingForAddRole && (
                                        <div className="flex justify-center mb-4">
                                            <Spinner/>
                                        </div>
                                    )}
                            {addNewRoleModalSuccessMessage && (
                                <h5 className="flex justify-center mb-4 text-xs font-medium text-green-700">* {addNewRoleModalSuccessMessage}</h5>
                            )}
                            {addNewRoleModalErrorMessage && (
                                <h5 className="flex justify-center mb-4 text-xs font-medium text-red-700">* {addNewRoleModalErrorMessage}</h5>
                            )}

                        <form className="space-y-6" action="#">
                            <div className="relative z-0 mb-6 w-full group">
                                <input type="text" name="floating_first_name" id="floating_first_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer" placeholder=" " onChange={(e) => setRoleName(e.target.value.trim())}  />
                                <label htmlFor="floating_first_name" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">*Role name</label>
                            </div>
            
                            <div className="relative z-0 mb-6 w-full group">
                                <input type="number" name="floating_first_name" id="floating_first_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer" placeholder=" " onChange={(e) => setMonthlyLimit(e.target.value)} />
                                <label htmlFor="floating_first_name" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">*Monthly limit</label>
                            </div>

                            <div className="relative z-0 mb-6 w-full group">
                                <input type="number" name="floating_first_name" id="floating_first_name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer" placeholder=" " onChange={(e) => setInflightLimit(e.target.value)} />
                                <label htmlFor="floating_first_name" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">*Inflight limit</label>
                            </div>

                            <div className='flex justify-center'>
                                <button type="button" className="w-40 text-white bg-mgray hover:bg-myellowdark hover:text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center" 
                                    onClick={() => 
                                    {
                                        setAddNewRoleModalErrorMessage(false)
                                        setAddNewRoleModalSuccessMessage(false)
                                        addRole();
                                    }}>Add Role
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddNewRole
