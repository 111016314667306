import React from 'react'
import Spinner from '../../Spinner'

const FetchTicket = ({setInputTicket,fetchTicket,TicketErrorMessage,isLoading}) => {
  return (
    <div>
         <div className="flex justify-center pt-8">
                <div className='flex justify-center w-80'>        
                    <label htmlFor="simple-search" className="sr-only">Search</label>
                    <div className="relative w-full">
                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                        </div>
                        <input type="text" id="simple-search" className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2.5" placeholder="Example: MWTR-1234" required=""
                        onChange={(e) => setInputTicket(e.target.value.trim()) }/>
                    </div>
                    <button type="button" className="p-2.5 ml-2 text-sm font-medium rounded-lg border border-gray-800 focus:ring-4 focus:outline-none bg-mgray hover:bg-myellowdark hover:border-white hover:text-black text-white" onClick={() => {fetchTicket()}}>
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </button>
                </div>
         </div>


        <div className='flex justify-center pt-5'>
            {TicketErrorMessage && ( <h5 className="flex justify-center mb-4 text-xs font-medium text-red-700">* {TicketErrorMessage}</h5>)} 
        </div>

        <div className='flex justify-center pt-5'>
            {isLoading && (
                <Spinner />
            )}
        </div>
    </div>
  )
}

export default FetchTicket