import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    
    const refresh = async () => {
        const response = await axios.get('/refresh', {
            withCredentials: true
        });
        setAuth(prev => {
            JSON.stringify(prev)
            return { 
                ...prev, 
                username: response.data.username,
                email: response.data.email,
                title: response.data.title,
                userLimits: response.data.userLimits,
                isAdmin: response.data.isAdmin,
                accessToken: response.data.accessToken 
            }
        });
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;