import React from 'react'

const ReportFilter = ({project, handleFilter,setProject,setYear,years,setQuarter}) => {
  return (
    // <>
    // {project == "last_ten_escalations" && (
    <div className="flex items-center">
        <div className="pb-1">
            <select defaultValue={"last_ten_escalations"} onChange={(e) => setProject(e.target.value)} id="underline_select" className="block w-48 py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                <option value="last_ten_escalations">Last 10 escalations</option>
                <option value="mwtr">MWTR</option>
                <option value="cjrnl">CJRNL</option>
                <option value="all">All</option>
            </select>
        </div> 
        <div className="pb-1 pl-10">
            <select defaultValue={'year'} disabled={project === "last_ten_escalations"} onChange={(e) => setYear(e.target.value)} id="underline_select" className="block w-20 py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                <option value="year">Year</option>
                {years.map((year, i) => (
                    <option value={year}>{year}</option>
                ))}
            </select>
        </div> 
        <div className="pb-1 pl-10">
            <select defaultValue={"quarter"} disabled={project === "last_ten_escalations"} onChange={(e) => setQuarter(e.target.value)} id="underline_select" className="block w-20 py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                <option value="quarter">Quarter</option>
                <option value="q1">Q1</option>
                <option value="q2">Q2</option>
                <option value="q3">Q3</option>
                <option value="q4">Q4</option>
            </select>
        </div> 
        <div className="pb-1 pl-10 mt-2">
            <button onClick={handleFilter} type="button" className="w-20 flex justify-center text-white bg-mgray hover:bg-myellowdark hover:text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm py-1 text-center">Fetch
            </button>
        </div> 
    </div>
    // )}
    // </>
  )
}

export default ReportFilter
