import React from 'react'
import AddNewRoleIcon from './AddNewRoleIcon';
import SearchRole from './SearchRole';
import Spinner from '../../Spinner';

const RoleTable = ({setSearchRole, setAddNewRoleModal, roles, searchRole, setRoleProps, deleteRole}) => {
  return (
    <div className='max-w-6xl flex px-10 justify-center container mx-auto pt-20'>
    <div className="scrollbar-hide max-h-[37.8rem] overflow-x-auto relative shadow-md sm:rounded-lg bg-gray-500 w-10/12">
        <table className="w-full text-center text-sm text-gray-500 dark:text-gray-400">
            <caption className="sticky top-0 p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-dark border-b-2 border-gray-500">
                    <div className="pt-1 flex items-center justify-between">
                        <SearchRole setSearchRole={setSearchRole}/>
                        <AddNewRoleIcon setAddNewRoleModal={setAddNewRoleModal}/>
                    </div>
            </caption>

            <thead className="sticky top-[88px] text-xs text-gray-900 uppercase bg-white border-b-2 border-gray-500">
                <tr>
                    <th scope="col" className="py-3 px-6">
                        Index
                    </th>
                    <th scope="col" className="py-3 px-6">
                        Role name
                    </th>
                    <th scope="col" className="py-3 px-6">
                        Monthly
                    </th>
                    <th scope="col" className="py-3 px-6">
                        Inflight
                    </th>
                    <th scope="col" className="py-3 px-6">
                        Action
                    </th>
                </tr>
            </thead>

            <tbody>
                        {!roles
                            ? <tr>
                                <td colSpan="5" className="bg-gray-200 p-4">
                                    <div className="flex justify-center">
                                        <Spinner/>
                                    </div>
                            </td>
                            </tr>
                            : !roles.length
                            ? <tr>
                                <td className="bg-gray-200 p-4" colSpan="5">No data available</td>
                            </tr>
                            : <> 
                                {roles.filter((val) => {
                                        if(!searchRole){
                                            return val;
                                        }else if(val.roleName.toLowerCase().includes(searchRole.toLowerCase())) {
                                            return val;
                                        }
                                }).map((role, i) => {
                                        return (
                                            <tr key={i} className="bg-white text-gray-900 border-b hover:bg-myellow">
                                                <th scope="row" className="py-4 px-6">
                                                    {i+1}
                                                </th>
                                                <td className="py-4 px-6 font-medium font-semibold text-gray-900">
                                                    {role?.roleName}
                                                </td>
                                                <td className="py-4 px-6">
                                                    {role?.monthly}
                                                </td>
                                                <td className="py-4 px-6">
                                                    {role?.inflight}
                                                </td>
                                                <td className="py-4 px-6">
                                                    <div className='flex space-x-2 justify-center'>                                                                                         
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-mgray cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={() => {setRoleProps(role?._id)}}>
                                                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                                        </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-orange-600 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} onClick={() => {deleteRole(role?._id)}}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        )})
                                }
                                </>}
            </tbody>
        </table>
    </div>
</div>
  )
}

export default RoleTable
